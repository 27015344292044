
import { Avatar, Iphone } from '@element-plus/icons-vue'
import { defineComponent, ref } from 'vue'

import LoginAccount from './LoginAccount.vue'
import LoginPhone from './LoginPhone.vue'
import Login from './Login.vue'
import PhoneBackBtn from '../../../components/Nav/PhoneBackBtn.vue'

import localCache from '../../../utils/cache'

export default defineComponent({
  components: {
    Avatar,
    Iphone,
    LoginAccount,
    LoginPhone,
    Login,
    PhoneBackBtn
  },
  setup() {
    const isKeepPassword = ref(true)
    const currentTab = ref<string>('login')

    // const test = ref<string>()
    // test = 'wxl'
    /**
     * class c {
     *   x=0
     * }
     * type T = InstanceType<typeof c>
     * T 就等于 c
     */
    const accountRef = ref<InstanceType<typeof LoginAccount>>() //拥有构造函数的实例（拿到类型->再拿到它对应的实例)
    const loginRef = ref<InstanceType<typeof Login>>()
    const phoneRef = ref<InstanceType<typeof LoginPhone>>()
    function submitForm() {
      console.log(localCache.getCache('token') ?? '没有')
      if (currentTab.value === 'register') {
        accountRef.value?.loginAction(isKeepPassword.value)
      } else if (currentTab.value === 'login') {
        loginRef.value?.loginAction(isKeepPassword.value)
      } else {
        console.log('手机登录')
      }
    }
    return {
      isKeepPassword,
      submitForm,
      accountRef,
      loginRef,
      phoneRef,
      currentTab,
    }
  },
})
