
import { defineComponent, reactive,ref,watchEffect } from 'vue'
import type { FormInstance } from 'element-plus'
import localCache from '../../../utils/cache'
import Code from '../../../service/login/login'
import { rules } from '../config/account-config'

import { useRoute,useRouter } from 'vue-router'

import { useStore } from '../../../store/index'

export default defineComponent({
  name: 'LoginAccount',
  setup(props, content) {
    const store = useStore()
    const route=useRoute()
    const router=useRouter()
    
    const captchaCodeImg=ref()

    const account = reactive({
      userName: localCache.getCache('name') ?? '',
      passWord: localCache.getCache('password') ?? '',
    })
    const sumcode = reactive({
      code: '',
      uuid: ''
    })
    const ruleFormRef = ref<FormInstance>()
    let flag=true

    //监听是否刷新验证码
    watchEffect(()=>{
      if(store.state.login.isRefReshCode===true){
        //重新获取验证码
        getCode()
        store.commit('login/changeisRefReshCode',false)
      }
      console.log('store.state.login.token',store.state.login.token)
      // if(store.state.login.token!=='' && flag){
      //    router.push(route.query.redirect+'')
      //    flag=false
      // }
    })

    const loginAction = (isKeepPassword:boolean)=>{
      ruleFormRef.value?.validate((valid)=>{
        if(valid){
          //是否记住密码
          if(isKeepPassword){
            localCache.setCache('name',account.userName)
            localCache.setCache('password',account.passWord)
          }else{
            localCache.deleteCache('name')
            localCache.deleteCache('password')
          }
          //登录验证
          store.dispatch('login/accountLoginAction',{...account,...sumcode})
        }
      })
    } 
    //获取验证码
     async function getCode() {
      const codeData = await Code()
      captchaCodeImg.value=codeData.data.img
      sumcode.uuid=codeData.data.uuid
    }
    getCode()
    return {
      account,
      rules,
      loginAction,
      ruleFormRef,
      sumcode,
      getCode,
      captchaCodeImg
    }
  },
})
